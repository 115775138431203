import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import { Container, Dialog, NoSsr } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import theme from "../../theme";
import { Trans } from "@lingui/macro";
import PersonalProfileTab from "./personal-profile-tab";
import TabPanel from "./tab-panel";
import TabLabel from "./tab-label";
import UserIcon from "../../icons/user-icon";
import PadlockIcon from "../../icons/padlock-icon";
import BellIcon from "../../icons/bell-icon";
import NotificationTab from "./notification-tab";
import ResetPasswordTab from "./reset-password-tab";
import { t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import DialogCancel from "./dialog-cancel";

const a11yProps = (index) => {
  return {
    id: `tab-${index}`,
    "aria-controls": `tabpanel-${index}`,
  };
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flex: 1,
    paddingTop: theme.spacing(2),
    height: "100%",
    [theme.breakpoints.down("xs")]: {
      padding: 0,
    },
  },
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  tabs: {
    minWidth: 200,
    borderBottom: 0,
    marginTop: theme.spacing(6),
    "& .MuiTabs-indicator": {
      left: 0,
      backgroundColor: theme.palette.primary.main,
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: 0,
      "& .Mui-selected": {
        backgroundColor: theme.palette.primary.main,
        "& .MuiTypography-root": {
          color: "white",
        },
      },
    },
  },
  tab: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
}));
const ProfileComponent = ({
  user,
  onSubmitProfile,
  userPatchStatus,
  onSubmitResetPassword,
  resetPasswordStatus,
  onStartUpdateFile,
  onUpdateFile,
  onErrorUpdateFile,
  fileUploadStatus,
}) => {
  const classes = useStyles(theme);
  const { i18n } = useLingui();
  const [value, setValue] = useState({ active: 0, nextActive: 0 });
  const [orientation, setOrientation] = useState("vertical");
  const [openCancel, setOpenCancel] = useState(false);
  const [hasAnyChange, setHasAnyChange] = useState({ status: false, payload: {}, from: "" });

  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleChange = (event, newValue) => {
    if (hasAnyChange.status) {
      setValue({ active: value.active, nextActive: newValue });
      setOpenCancel(true);
    } else {
      setValue({ active: newValue, nextActive: newValue });
    }
  };

  const handleSubmitDialog = () => {
    switch (hasAnyChange.from) {
      case "profile":
        onSubmitProfile({ payload: hasAnyChange.payload, userId: user.id });
        break;
      case "resetPassword":
        onSubmitResetPassword(hasAnyChange.payload);
        break;
    }
    setValue({ active: value.nextActive, nextActive: value.nextActive });
    setHasAnyChange({ status: false, payload: {}, from: "" });
    setOpenCancel(false);
  };

  const handleCancelDialog = () => {
    setHasAnyChange({ status: false, payload: {}, from: "" });
    setValue({ active: value.nextActive, nextActive: value.nextActive });
    setOpenCancel(false);
  };

  useEffect(() => {
    const orientation = fullScreen ? "horizontal" : "vertical";
    setOrientation(orientation);
  }, [fullScreen]);

  return (
    <Container className={classes.container}>
      <NoSsr>
        <Box mb={2} position="absolute" sx={{ display: { xs: "none", md: "block" } }}>
          <Typography variant="h1" color="textPrimary">
            <Trans>Cuenta</Trans>
          </Typography>
        </Box>
        <Box className={classes.root}>
          <Tabs
            orientation={orientation}
            variant="scrollable"
            value={value.active}
            onChange={handleChange}
            aria-label="Profile"
            className={classes.tabs}
          >
            <Tab
              label={
                <TabLabel tabName={i18n._(t`Información personal`)}>
                  <UserIcon width={13} height={13} />
                </TabLabel>
              }
              className={classes.tab}
              {...a11yProps(0)}
            />
            <Tab
              label={
                <TabLabel tabName={i18n._(t`Inicio de sesión`)}>
                  <PadlockIcon width={13} height={13} />
                </TabLabel>
              }
              className={classes.tab}
              {...a11yProps(1)}
            />
            <Tab
              label={
                <TabLabel tabName={i18n._(t`Notificaciones`)}>
                  <BellIcon width={13} height={13} />
                </TabLabel>
              }
              {...a11yProps(2)}
            />
          </Tabs>
          <TabPanel value={value.active} index={0}>
            <PersonalProfileTab
              user={user}
              onSubmit={onSubmitProfile}
              userPatchStatus={userPatchStatus}
              setHasAnyChange={setHasAnyChange}
              onStartUpdateFile={onStartUpdateFile}
              onUpdateFile={onUpdateFile}
              onErrorUpdateFile={onErrorUpdateFile}
              fileUploadStatus={fileUploadStatus}
            />
          </TabPanel>
          <TabPanel value={value.active} index={1}>
            <ResetPasswordTab
              onSubmit={onSubmitResetPassword}
              resetPasswordStatus={resetPasswordStatus}
              setHasAnyChange={setHasAnyChange}
            />
          </TabPanel>
          <TabPanel value={value.active} index={2}>
            <NotificationTab />
          </TabPanel>
        </Box>
        <DialogCancel
          open={openCancel}
          onClose={() => setOpenCancel(false)}
          onCancelAndContinue={handleCancelDialog}
          onSubmitAndContinue={handleSubmitDialog}
        />
      </NoSsr>
    </Container>
  );
};

export default ProfileComponent;
