import React, { useEffect } from "react";
import Navbar from "/src/containers/navbar";
import BacThemeProvider from "/src/providers/bac-theme-provider";
import Contact from "/src/components/home/contact";
import Footer from "/src/components/footer";
import config from "/src/config.js";
import SEO from "/src/components/seo";
import Profile from "/src/containers/profile";
import SetupContainer from "/src/setup-container";
import { useNavigate } from "/src/hooks/navigation";
import { useSelector } from "react-redux";

const ProfilePage = () => {
  const { navigate } = useNavigate();
  const isAuthenticated = useSelector((state) => state.user.isAuthenticated);
  const status = useSelector((state) => state.user.status);

  useEffect(() => {
    const userId = localStorage.getItem("user_id");
    if (!userId || (userId && !isAuthenticated && status === "success")) {
      navigate(`/signin`);
    }
  }, [status]);

  return (
    <>
      <SetupContainer />
      <SEO
        title={`${config.CLIENT_NAME} - Profile`}
        description={config.CLIENT_DESCRIPTION}
        image={encodeURI(config.LOGO_DARK) || ""}
      />
      <BacThemeProvider>
        <Navbar />
        {isAuthenticated && <Profile />}
        <Contact />
        <Footer />
      </BacThemeProvider>
    </>
  );
};

export default ProfilePage;
